import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRouteParams = (callback: (params: string) => void) => {
    const location = useLocation();

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean); // Split the path into segments and filter out empty strings

        if (pathSegments.length > 1) {
            const allParams = pathSegments.slice(1).join('/'); // Join the segments into a single string separated by "/"
            callback(allParams); 
        } else {

        }
    }, [location.pathname, callback]);
};

export default useRouteParams;
export {};