import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useRouteParameter = () => {
    const location = useLocation();

    // Memoize the calculation of the last parameter and fullPath based on location.pathname
    const routeParams = useMemo(() => {
        // Split the path into segments and filter out empty strings
        const pathSegments = location.pathname.split('/').filter(Boolean);

        // Get the last parameter only if the path has more than one segment
        const lastParam = pathSegments.length > 1 ? pathSegments[pathSegments.length - 1] : null;
        const orgId = lastParam ? parseInt(lastParam, 10) : null;

        // Rebuild the full path from the second segment onwards
        const fullPath = pathSegments.slice(1).join('/') ? "/" + pathSegments.slice(1).join('/') : null;

        return { lastParam: orgId, fullPath };
    }, [location.pathname]); // Only recalculate when location.pathname changes

    return routeParams; // Return the memoized result
};

export default useRouteParameter;

