import { createSlice } from "@reduxjs/toolkit";
import {
  createOrganizationAsync,
  createUserAsync,
  fetchOrganizationDetailsAsync,
  fetchOrganizationHierarchyAsync,
  fetchOrganizationListAsync,
  getUserAccountDetailsAsync,
  loginUserAsync,
} from "../thunks/OrganizationThunk";

export interface User {
  id: string;
  first: string;
  last: string;
  email: string;
  external_unique_id: string;
}
export interface Organization {
  id: number;
  name: string;
  displayName: string;
  contactName: string;
  contactEmail: string;
  parentOrgId: number | null;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

interface OrganizationList {
  page: number;
  per_page: number;
  total_pages: number;
  total_items: number;
  orgId: number;
  items: Organization[];
}

interface LoginResponse {
  access_token: string;
  refresh_token: string;
}
interface UserAccount{
email: string;
email_verified: boolean;
external_unique_id: string|null;
id:string;
job_title: string|null;
first:string|null;
last: string|null;
middle: string|null;
mobile_phone: string|null;
organization_id: string|null;
user_role: string|null;
}

export const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  IDLE: "idle",
  FAILED: "failed",
};
const initialOrganizationListState: OrganizationListState = {
  OrganizationList: null,
  loginResponse: null,
  loginStatus: STATUS.IDLE,
  createOrgStatus: STATUS.IDLE,
  createOrgResponse: null,
  createUserStatus: STATUS.IDLE,
  createUserResponse: null,
  status: STATUS.IDLE,
  error: null,
  userAccount: null,
  ParentOrganizationList: null,
  selectedParentOrgId: null,
  selectedOrgDetails:null,
  parentHierarchyOrganizationList: null,
};

interface OrganizationListState {
  OrganizationList: any | null;
  loginResponse: LoginResponse | null;
  loginStatus: string;
  createOrgStatus: string;
  createOrgResponse: any;
  createUserStatus: string;
  createUserResponse: any;
  status: string;
  error: string | null;
  userAccount: UserAccount | null;
  ParentOrganizationList: OrganizationList | null;
  selectedParentOrgId: number|null;
  selectedOrgDetails: any;
  parentHierarchyOrganizationList:Organization[] |null
}


const DelearDetailsSlice = createSlice({
  name: "OrganizationList",
  initialState: initialOrganizationListState,
  reducers: {

    resetLoginStatus: state => {
      state.loginStatus = STATUS.IDLE;
    },
    resetCreateOrgStatus: state => {
      state.createOrgStatus = STATUS.IDLE;
    },
    resetCreateUserStatus: state => {
      state.createUserStatus = STATUS.IDLE;
    },
    setParentOrgId: (state,action) => {
      state.selectedParentOrgId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationListAsync.pending, (state) => {
        state.status = STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchOrganizationListAsync.fulfilled, (state, action) => {
        if (action.payload?.orgId>0)
        {
          state.OrganizationList = action.payload;
        }
        else
        {
          state.ParentOrganizationList = action.payload
        }
       

        state.status = STATUS.SUCCESS;
      })
      .addCase(fetchOrganizationListAsync.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })

      .addCase(loginUserAsync.pending, (state) => {
        state.loginStatus = STATUS.LOADING;
        state.error = null;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loginResponse = action.payload;
        state.loginStatus = STATUS.SUCCESS;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loginStatus = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })
      .addCase(createOrganizationAsync.pending, (state) => {
        state.createOrgStatus = STATUS.LOADING;
        state.error = null;
      })
      .addCase(createOrganizationAsync.fulfilled, (state, action) => {
        state.createOrgResponse = action.payload;
        state.createOrgStatus = STATUS.SUCCESS;
      })
      .addCase(createOrganizationAsync.rejected, (state, action) => {
        state.createOrgStatus = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })


      .addCase(createUserAsync.pending, (state) => {
        state.createUserStatus = STATUS.LOADING;
        state.error = null;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.createUserResponse = action.payload;
        state.createUserStatus = STATUS.SUCCESS;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.createUserStatus = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })
      .addCase(getUserAccountDetailsAsync.pending, (state) => {
        state.error = null;
        state.status = STATUS.LOADING;
      })
      .addCase(getUserAccountDetailsAsync.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.userAccount = action.payload;
      })
      .addCase(getUserAccountDetailsAsync.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchOrganizationDetailsAsync.pending, (state) => {
        state.error = null;
        state.status = STATUS.LOADING;
      })
      .addCase(fetchOrganizationDetailsAsync.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.selectedOrgDetails = action.payload;
      })
      .addCase(fetchOrganizationDetailsAsync.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchOrganizationHierarchyAsync.pending, (state) => {
        state.error = null;
        state.status = STATUS.LOADING;
      })
      .addCase(fetchOrganizationHierarchyAsync.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.parentHierarchyOrganizationList = action.payload;
      })
      .addCase(fetchOrganizationHierarchyAsync.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message ?? null;
      })
  },
});
export const { resetLoginStatus, resetCreateOrgStatus,resetCreateUserStatus, setParentOrgId } = DelearDetailsSlice.actions;
export default DelearDetailsSlice.reducer;
