import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { setParentOrgId } from '../../store/slices/OrganizationSlice';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu, DropdownDivider } from '../Catalyst/dropdown';
import { Avatar } from '../Catalyst/avatar';
import { SidebarItem, SidebarLabel } from '../Catalyst/sidebar';
import { UserIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import { Organization } from '../../store/slices/OrganizationSlice';
import logo from '../../assets/FinleyLogo.svg'; 
import { fetchOrganizationListAsync } from '../../store/thunks/OrganizationThunk';

const HeaderContent = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const organizationListData = useSelector((state: RootState) => state.OrganizationListStore?.ParentOrganizationList);
  const selectedParentOrgId = useSelector((state: RootState) => state.OrganizationListStore?.selectedParentOrgId)??'';

  const [organization, setOrganization] = useState<Organization | null>(null);
useEffect(() => {
 const fetchData = async()=>{
   await dispatch(fetchOrganizationListAsync({
    page: 1,
    per_page: 25,
    orgId: 0,
}));
 }
 fetchData();
}, [dispatch])

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const items = organizationListData?.items || [];
    let orgId: number = 0;
    if (pathSegments.length < 3 && selectedParentOrgId ) { 
      orgId = selectedParentOrgId;
    }else
    {
      orgId = parseInt(pathSegments[2]);
    }
  
    if (orgId > 0) {
     
      const filteredOrganizations = items.find((x: Organization) => x.id === orgId);
      setOrganization(filteredOrganizations || null); // Set org or null if not found
      dispatch(setParentOrgId(orgId));
    } else {
      setOrganization(items[0] || null); // Set the first item or null if items are empty
    }
  }, [ location.pathname, organizationListData?.items,selectedParentOrgId, dispatch]);

  const selectedItem = organization?.displayName; // Default display name

  const dropdownItems = () => {
    return organizationListData?.items.map((item: Organization) => (
      <DropdownItem key={item.id} href={`/org-home/${item.id}`}>
        <UserIcon />
        <DropdownLabel>{item.displayName}</DropdownLabel>
      </DropdownItem>
    ));
  };

  return (
      <Dropdown>
        <DropdownButton as={SidebarItem} className="lg:mb-2.5">
          <Avatar src={logo} />
          <SidebarLabel>{selectedItem}</SidebarLabel>
          <UserIcon />
        </DropdownButton>
        <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
          {dropdownItems()}

          <DropdownDivider />
          <DropdownItem href="/create-org">
            <PlusIcon />
            <DropdownLabel>New Parent Organization&hellip;</DropdownLabel>
          </DropdownItem>
          <DropdownItem>

          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
  );
});

export default HeaderContent;