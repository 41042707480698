import { default as http } from "../rest/http";

export const fetchUsersList = async (page: number, per_page: number, orgId: number) => {
  try {
    const url  = `/master-data/users?page=${page}&per_page=${per_page}&organization_id=${orgId}`;
    const response = await http.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchSearchedUsersList = async (page: number, per_page: number, orgId: number, searchText: string|undefined) => {
  try {
    let url=`/master-data/users?page=${page}&per_page=${per_page}&organization_id=${orgId}`;
    if (searchText)
    {
       url  = `/master-data/users?page=${page}&per_page=${per_page}&organization_id=${orgId}&searchString=${searchText}`;
    }
   
    const response = await http.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


