
import {  useSelector } from 'react-redux';
import TileComponent from '../../common/TileComponent'
import UsersTable from '../UsersTable/UsersTable'
import { RootState } from '../../../store/store';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../Catalyst/dropdown';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Input, InputGroup } from '../../Catalyst/input';
import { useCallback, useEffect, useState } from 'react';
import { Select } from '../../Catalyst/select';
import { debounce } from "lodash";
import React from 'react';
import useRouteParameter from '../../../hooks/useRouteParamater';
export function OrgHome() {
  const [searchText, setSearchText] = React.useState<string>();
  const [selectedOrgId, setSelectedOrgId] = useState<number | undefined | null>(null);
  // const selectedParentOrgId = useSelector((state: RootState) => state.OrganizationListStore?.selectedParentOrgId) ?? undefined;
  const userAccount = useSelector((state: RootState) => state.OrganizationListStore?.userAccount);
  const {lastParam, fullPath} = useRouteParameter();

useEffect(() => {
  setSelectedOrgId(lastParam); //
}, [lastParam])

  const debouncedSetSearchText = useCallback(
    debounce((value: string) => {
      setSearchText(value); // Update searchText state after delay
    }, 100),
    [setSearchText]
  );

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    debouncedSetSearchText(query); // Debounce the setSearchText function
  };

  return (
    <div className="ml-5  pr-32">
      <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 dark:text-white pt-10">Hello, {userAccount?.first || ''} {userAccount?.last || ''}</h1>
      <div className='mt-8 flex items-end justify-between'>
        <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white">Overview</h2>
        <div> <Select name="status">
          <option value="lastweek">Last week</option>
          <option value="last2week">Last two week</option>
          <option value="lastmonth">Last month</option>
          <option value="lastquarter">Last quarter</option>
        </Select></div>
      </div>
      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <TileComponent />
      </div>
      <div className='mt-8 flex items-end justify-between'>
        <div></div>
        <div className='flex'>
          <div className='px-3'> <InputGroup>
            <MagnifyingGlassIcon />
            <Input name="search" type='search' placeholder="Search&hellip;" aria-label="Search" onChange={handleChange} />
          </InputGroup></div>
          <div>
            <Dropdown>
              <DropdownButton outline>
                Create User
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                <DropdownItem href={`/create-user${fullPath}`}>Create a single user</DropdownItem>
                <DropdownItem href="#">Create a group user via .csv</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        {selectedOrgId &&
          <UsersTable selectedOrgId={lastParam||selectedOrgId} searchText={searchText} />
        }

      </div>
    </div>
  )
}


