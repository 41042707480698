
import axios from 'axios';
import { Store } from '@reduxjs/toolkit';
// import { readFromLocalStorage } from '../utils/DataEncryption';

const baseURL = process.env.REACT_APP_BASE_URL;

const defaultHeaders = {
  'Content-Type': 'application/json',
};

let store: Store | null = null;

export const injectStore = (_store: Store) => {
  store = _store;
};

export const setRequestHeaders = (requestHeaders: any = {},contentType="application/json") => {
  const token =  localStorage.getItem('accessToken');
  //  const token = ";
  const headers = {
    'Content-Type': contentType,
    'authorization':`Bearer ${token}`,
    ...requestHeaders,
  };
  // If the content type is multipart/form-data, let Axios handle the content type
  if (contentType === 'multipart/form-data') {
    delete headers['Content-Type']; // Remove the Content-Type header for FormData
  } else {
    headers['Content-Type'] = contentType;
  }
  return headers;
};


const http = {

  get: async (url: string) => {
    try {
      let requestHeaders: any = setRequestHeaders();
      const response = await axios.get(`${baseURL}${url}`, { headers: requestHeaders });
      return response.data;
    } catch (error) {
      console.error('Error making GET request:', error);
      throw error;
    }
  },

  post: async (url: string, data: any,contentType="application/json") => {
    try {
      let requestHeaders: any = setRequestHeaders({},contentType);
      const response = await axios.post(`${baseURL}${url}`, data, { headers: requestHeaders });
      return response.data;
    } catch (error) {
      console.error('Error making POST request:', error);
      throw error;
    }
  },

  put: async (url: string, data: any,contentType="application/json") => {
    try {
      let requestHeaders: any = setRequestHeaders({},contentType);
      const response = await axios.put(`${baseURL}${url}`, data, { headers: requestHeaders });
      return response.data;
    } catch (error) {
      console.error('Error making PUT request:', error);
      throw error;
    }
  },

  delete: async (url: string) => {
    try {

      let requestHeaders = setRequestHeaders();
      const response = await axios.delete(`${baseURL}${url}`, { headers: requestHeaders });
      return response.data;
    } catch (error) {
      console.error('Error making DELETE request:', error);
      throw error;
    }
  },
};

export default http;
