// usersThunk.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSearchedUsersList, fetchUsersList } from "../../services/UsersService";


// Thunk to fetch users
export const fetchUsersListAsync = createAsyncThunk(
  "users/fetchUsersList",
  async ({ page, per_page, orgId }: { page: number; per_page: number; orgId: number }, { rejectWithValue }) => {
    try {
      const response = await fetchUsersList(page, per_page, orgId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

/// Thunk to fetch matching organizations
export const fetchSearchedUsersListAsync = createAsyncThunk(
  "users/fetchSearchedUsersList",
  async ({ page, per_page, orgId, searchString }: { page: number; per_page: number; orgId: number; searchString: string|undefined }, { rejectWithValue }) => {
    try {
      const response = await fetchSearchedUsersList(page, per_page, orgId, searchString); // Use the correct service function
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);