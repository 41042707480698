import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from "../../Catalyst/table";
import { User } from "../../../store/slices/OrganizationSlice";
import { fetchSearchedUsersListAsync } from "../../../store/thunks/UsersThunk";
import { Pagination, PaginationPrevious, PaginationList, PaginationPage, PaginationGap, PaginationNext } from "../../Catalyst/pagination";

interface UsersTableProps {
  selectedOrgId: any | undefined;
  searchText?: string | undefined;
}

const UsersTable = ({ selectedOrgId, searchText }: UsersTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const dispatch = useDispatch<AppDispatch>();

  const { users, status, error, total_pages, total_items, filteredUsers } = useSelector(
    (state: RootState) => state.UserStore
  );

  useEffect(() => {
    const fetchOrganizations = async () => {
      await dispatch(
        fetchSearchedUsersListAsync({
          page: currentPage,
          per_page: perPage,
          orgId: selectedOrgId ? selectedOrgId : 0,
          searchString: searchText || '', // Provide a default empty string if searchText is undefined
        })
      );
    };

    fetchOrganizations();
  }, [currentPage, perPage, selectedOrgId, searchText, dispatch]);

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to page 1
  };

  // if (status === "loading") {
  //   return <div>Loading users...</div>;
  // }

  if (status === "failed") {
    return <div>Error fetching users: {error}</div>;
  }

  // Filter users based on searchText if needed
  // const filteredUsers = searchText
  //   ? users.filter((user: User) => {
  //       const searchLower = searchText.toLowerCase();
  //       return (
  //         user.first?.toLowerCase().includes(searchLower) ||
  //         user.external_unique_id?.toLowerCase().includes(searchLower) ||
  //         user.email?.toLowerCase().includes(searchLower)
  //       );
  //     })
  //   : users;

  return (
    <div>
      <Table className="mt-8">
        <TableHead>
          <TableRow className="text-sm text-tableTitle ">
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>External Unique Id</TableHeader>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredUsers.length>0 && filteredUsers.map((item: User) => (
            <TableRow key={item?.id}>
              <TableCell className="text-sm">{item?.first} {item?.last}</TableCell>
              <TableCell className="text-sm">{item?.email}</TableCell>
              <TableCell className="text-sm">{item?.external_unique_id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <Pagination className="mt-10 pr-5">
        <PaginationPrevious
          onClick={() => { setCurrentPage(currentPage - 1) }}
          disabled={currentPage === 1}
        />
        <PaginationList>
          {[...Array(total_pages || 1)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <PaginationPage
                key={pageNum}
                onClick={() => setCurrentPage(pageNum)}
                current={pageNum === currentPage}
              >
                {pageNum}
              </PaginationPage>
            );
          })}
          {total_pages > 5 && (
            <>
              <PaginationGap />
              <PaginationPage
                onClick={() => setCurrentPage(total_pages)}
                current={currentPage === total_pages}
              >
                {total_pages}
              </PaginationPage>
            </>
          )}
        </PaginationList>
        <PaginationNext
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === total_pages}
        />
      </Pagination>
    </div>
  );
};

export default UsersTable;
