// usersSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersListAsync, fetchSearchedUsersListAsync } from "../thunks/UsersThunk";

interface UsersState {
  users: any[];  // Replace with proper User type if needed
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  total_pages: number;
  total_items: number;
  filteredUsers: any[];
}

const initialState: UsersState = {
  users: [],
  status: "idle",
  error: null,
  total_pages: 0,
  total_items: 0,
  filteredUsers: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload.items; 
        state.total_pages = action.payload.total_pages;
        state.total_items = action.payload.total_items;
      })
      .addCase(fetchUsersListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchSearchedUsersListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSearchedUsersListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filteredUsers = action.payload.items; 
        state.total_pages = action.payload.total_pages;
        state.total_items = action.payload.total_items;
      })
      .addCase(fetchSearchedUsersListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      ;
  },
});

export default usersSlice.reducer;
