import { useEffect, useState } from "react";
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from "../../Catalyst/table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { fetchOrganizationListAsync } from "../../../store/thunks/OrganizationThunk";
import { Organization } from "../../../store/slices/OrganizationSlice";
import { Pagination, PaginationPrevious, PaginationList, PaginationPage, PaginationGap, PaginationNext } from "../../Catalyst/pagination";


interface OrganizationTableProps {
  selectedOrgId: any | null;
  handleViewOrganization: (orgId: number) => void;
}

const OrganizationTable = ({ selectedOrgId, handleViewOrganization }: OrganizationTableProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  useEffect(() => {
    const fetchOrganizations = async () => {
      await dispatch(
        fetchOrganizationListAsync({
          page: currentPage,
          per_page: perPage,
          orgId: selectedOrgId || 0,
        })
      );
    };

    fetchOrganizations();
  }, [currentPage, perPage, selectedOrgId, dispatch]);

  const data = useSelector(
    (state: RootState) => state.OrganizationListStore.OrganizationList
  );
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(1);
  };


  if (data && data.items && !data.items.length) {
    return <div className="mt-8 text-center text-lg font-medium">No organizations found</div>;
  }

  if (data && !data.items && !data.length) {
    return <div className="mt-8 text-center text-lg font-medium">No organizations found</div>;
  }

  return (
    <div>
      <Table className="mt-8">
        <TableHead>
          <TableRow className="font-bold text-sm text-tableTitle">
            {/* <TableHeader>Id</TableHeader> */}
            <TableHeader>Organization</TableHeader>
          </TableRow>
        </TableHead>

        <TableBody>
          {(data?.items || data)?.map((item: Organization) => (
            <TableRow key={item?.id}>
              <TableCell onClick={() => handleViewOrganization(item?.id)}
                className="cursor-pointer">{item?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination className="mt-10">
        <PaginationPrevious
          onClick={() => { setCurrentPage(currentPage - 1) }}
          disabled={currentPage === 1}
        />
        <PaginationList>
          {[...Array(data?.total_pages || 1)].map((_, index) => {
            const pageNum = index + 1;
            return (
              <PaginationPage
                key={pageNum}
                onClick={() => setCurrentPage(pageNum)}
                current={pageNum === currentPage}
              >
                {pageNum}
              </PaginationPage>
            );
          })}
          {data?.total_pages > 5 && (
            <>
              <PaginationGap />
              <PaginationPage
                onClick={() => setCurrentPage(data?.total_pages)}
                current={currentPage === data?.total_pages}
              >
                {data?.total_pages}
              </PaginationPage>
            </>
          )}
        </PaginationList>
        <PaginationNext
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === data?.total_pages}
        />
      </Pagination>
    </div>
  );
};

export default OrganizationTable;
