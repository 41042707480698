import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Button } from "../../Catalyst/button";
import { useNavigate } from "react-router";
import { fetchOrganizationDetailsAsync } from "../../../store/thunks/OrganizationThunk";
import OrganizationTable from "../OrganizationTable/OrganizationTable";
import { AppDispatch, RootState } from "../../../store/store";
import useRouteParameter from "../../../hooks/useRouteParamater";


export const OrgView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const orgDetails = useSelector((state: RootState) => state.OrganizationListStore?.selectedOrgDetails);
  const location = useLocation();

  const { lastParam, fullPath } = useRouteParameter();
  useEffect(() => {
    if (lastParam !== null) {
      // Create and call the async dispatch only when orgId is set
      (async () => {
        try {
          await dispatch(fetchOrganizationDetailsAsync(lastParam || 0)); // Dispatch the async action
        } catch (error) {
          console.error("Error fetching organization details:", error);
        }
      })();
    }
  }, [lastParam, dispatch]);

  const handleViewOrganization = async (orgId: number) => {
    try {
      const response = await dispatch(fetchOrganizationDetailsAsync(orgId));
      if (response?.payload) {
        navigate(location.pathname + "/" + orgId);
      } else {
        console.error("Failed to fetch organization details.");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  const handleCreateEntity = () => {
    navigate(`/create-org${fullPath}`, { state: { selectedOrgDetails: lastParam } });
  }

  return (
    <>
      <div className="ml-5  pr-32" >
        <div className="flex justify-between items-center mr-10">
          <div className="mb-6 mt-10 text-xl font-semibold">
            {orgDetails?.name || ''}
          </div>
          <Button color="white"
            onClick={handleCreateEntity}
            className="mt-4 text-sm cursor-pointer h-10 flex justify-end"
          >
            Create Organization
          </Button>
        </div>
        <OrganizationTable
            selectedOrgId={lastParam}
            handleViewOrganization={handleViewOrganization}
          />
       
      </div>
    </>
  );
};
