import { configureStore } from '@reduxjs/toolkit';
import OrganizationListReducer from './slices/OrganizationSlice'
import UserReducer from './slices/UserSlice'

export const store = configureStore({
  reducer: {
    OrganizationListStore: OrganizationListReducer,
    UserStore: UserReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
