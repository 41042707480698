import { useEffect, useState } from "react";
import { Field, Label } from "../../Catalyst/fieldset";
import { Input } from "../../Catalyst/input";
import { Button } from "../../Catalyst/button";
import { Dialog, DialogActions, DialogDescription, DialogTitle } from "../../Catalyst/dialog";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { createOrganizationAsync } from "../../../store/thunks/OrganizationThunk";
import { STATUS, resetCreateOrgStatus } from "../../../store/slices/OrganizationSlice";
import useRouteParameter from "../../../hooks/useRouteParamater";

export const CreateOrg = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const selectedParentOrgId = useSelector(
    (state: RootState) => state.OrganizationListStore?.selectedParentOrgId
  ) ?? '';

  const [formData, setFormData] = useState({
    name: '',
    displayName: '',
    contactName: '',
    contactEmail: '',
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalDesc, setModalDesc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const organizationDetail = useSelector(
    (state: RootState) => state?.OrganizationListStore
  );

  const { lastParam, fullPath } = useRouteParameter();

  useEffect(() => {
    if (organizationDetail?.createOrgStatus === STATUS.SUCCESS) {
      setLoading(false);
      setModalTitle("Organization Created");
      setModalDesc(
        `${organizationDetail?.createOrgResponse?.message} the organization ID is ${organizationDetail?.createOrgResponse?.org_id}`
      );
      setIsModalOpen(true);
      // Clear the input fields after successful creation
      setFormData({
        name: '',
        displayName: '',
        contactName: '',
        contactEmail: '',
      });
    } else if (organizationDetail?.createOrgStatus === STATUS.FAILED) {
      setLoading(false);
      console.error("Error creating organization");
      setModalTitle("Error creating organization");
      setModalDesc("Organization could not be created, please try again later.");
      setIsModalOpen(true);
    }
  }, [organizationDetail?.createOrgStatus]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...formData,
      parentOrgId: lastParam, // Set parentOrgId to selectedOrgDetails.id
    };
    await dispatch(createOrganizationAsync(payload));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    if (!lastParam) {
      navigate(`/org-view/${lastParam || selectedParentOrgId}`);
    } else {
      navigate(`/org-view${fullPath}`);
    }
  };

  return (
    <div className="w-full">
      <div className="w-[50%] ml-5">
        <h1 className="mb-6 mt-10 text-xl font-semibold">Create Organization</h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          <Field>
            <Label>Full Name</Label>
            <Input
              value={formData.name}
              onChange={handleChange}
              name="name"
              placeholder="Enter organization name"
            />
          </Field>

          <Field>
            <Label>Display Name</Label>
            <Input
              value={formData.displayName}
              onChange={handleChange}
              name="displayName"
              placeholder="Enter display name"
            />
          </Field>

          <Field>
            <Label>Contact Name</Label>
            <Input
              value={formData.contactName}
              onChange={handleChange}
              name="contactName"
              placeholder="Enter contact name"
            />
          </Field>

          <Field>
            <Label>Contact Email</Label>
            <Input
              value={formData.contactEmail}
              onChange={handleChange}
              name="contactEmail"
              type="email"
              placeholder="Enter contact email"
            />
          </Field>

          <div className="flex space-x-4">
            <Button type="submit" className="cursor-pointer mt-4" disabled={loading}>
              {loading ? "Creating..." : "Create Organization"}
            </Button>
            <Button type="button" className="cursor-pointer mt-4" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>

        <Dialog open={isModalOpen} onClose={setIsModalOpen}>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogDescription>{modalDesc}</DialogDescription>
          <DialogActions>
            <Button plain onClick={() => { dispatch(resetCreateOrgStatus()); setIsModalOpen(false); }}>
              Create one more
            </Button>
            <Button className="cursor-pointer" onClick={async () => { await dispatch(resetCreateOrgStatus()); setIsModalOpen(false); handleCancel(); }}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
