import { Link } from '../Catalyst/link';

type Page = {
    name: string;
    href: string;
    current: boolean;
};

interface BreadCrumbsProps {
    pages: Page[];
}

export const BreadCrumbs = ({ pages }: BreadCrumbsProps) => {
    // Return null if pages has 1 or fewer elements
    if (pages.length <= 1) return null;

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                {pages.map((page, index) => (
                    <li key={index}>
                        <div className="flex items-center">
                            {index > 0 && (
                                <svg
                                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 6 10"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 9 4-4-4-4"
                                    />
                                </svg>
                            )}
                            {page.current ? (
                                <span
                                    className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400"
                                    aria-current="page"
                                >
                                    {page.name}
                                </span>
                            ) : (
                                <Link
                                    href={page.href}
                                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                                >
                                    {page.name}
                                </Link>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
};
