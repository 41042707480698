
import { Divider } from '../Catalyst/divider';
import { Badge } from '../Catalyst/badge';
import Tile from './Tile';
function TileComponent() {
    return (

        <>
            <Tile
                title="Total revenue"
                value="$2.6M"
                badgeColor="lime"
                badgeText="+4.5%"
                description="from last week"
            />
            <Tile
                title="Average order value"
                value="$455"
                badgeColor="pink"
                badgeText="-0.5%"
                description="from last week"
            />
            <Tile
                title="Tickets sold"
                value="5,888"
                badgeColor="lime"
                badgeText="4.5%"
                description="from last week"
            />
            <Tile
                title="Page views"
                value="823,067"
                badgeColor="lime"
                badgeText="+21.2%"
                description="from last week"
            />
        </>
    )
}

export default TileComponent