import React from 'react';
import { Divider } from '../Catalyst/divider';
import { Badge } from '../Catalyst/badge';

interface TileProps {
  title: string;
  value: string;
  badgeColor: any;
  badgeText: string;
  description: string;
}

const Tile: React.FC<TileProps> = ({ title, value, badgeColor, badgeText, description }) => {
  return (
    <div>
      <Divider />
      <div className='mt-6 text-lg/6 font-medium sm:text-sm/6mt-6 text-sm'>{title}</div>
      <div className='mt-3 text-3xl/8 font-semibold sm:text-2xl/8'>{value}</div>
      <div className='mt-3 flex items-center'>
        <Badge color={badgeColor}>{badgeText}</Badge>
        <div className='ml-1 text-sm text-tableTitle'>{description}</div>
      </div>
    </div>
  );
};

export default Tile;
