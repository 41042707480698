import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchOrganizationList,
  login,
  CreateOrganization,
  fetchOrganizationDetails,
  CreateUser,
  getUserAccountDetails,
  fetchOrganizationHierarchy,
} from "../../services/OrganizationService";
import { Pagination } from "../../types/CommonType";

export const fetchOrganizationListAsync = createAsyncThunk(
  "user/fetchOrganizations",
  async ({ page, per_page, orgId }: Pagination, thunkAPI) => {
    const response: any = await fetchOrganizationList(page, per_page, orgId);
    response.orgId = orgId;
    return response;
  }
);

export const fetchOrganizationDetailsAsync = createAsyncThunk(
  "user/fetchOrgDetails",
  async (orgId: number) => {
    const response: any = await fetchOrganizationDetails(orgId);
    return response;
  }
);

export const loginUserAsync = createAsyncThunk(
  "user/Login",
  async (data: any, thunkAPI) => {
    const response = await login(data);
    return response;
  }
);

export const createOrganizationAsync = createAsyncThunk(
  "user/CreateOrg",
  async (data: any, thunkAPI) => {
    const response = await CreateOrganization(data);
    return response;
  }
);

export const createUserAsync = createAsyncThunk(
  "user/CreateUser",
  async (data: any, { rejectWithValue }) => {
    try {
      return await CreateUser(data);
    } catch (error: any) {
      console.error("Error in createUserAsync:", error);
      return rejectWithValue(error?.message);
    }
  }
);

export const getUserAccountDetailsAsync = createAsyncThunk(
  "user/getUserAccountDetails",
  async () => {
    const response: any = await getUserAccountDetails();
    return response;
  }
);

export const fetchOrganizationHierarchyAsync = createAsyncThunk(
  "user/fetchOrganizationHierarchy",
  async (orgId: number) => {
    const response: any = await fetchOrganizationHierarchy(orgId);
    return response;
  }
);