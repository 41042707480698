import { default as http } from "../rest/http";

export const fetchOrganizationList = async (page: number, per_page: number, orgId: number=0) => {
  try {
    const url = orgId !== 0
      ? `/master-data/organizations/${orgId}/sub-orgs?page=${page}&per_page=${per_page}`
      : `/master-data/organizations?page=${page}&per_page=${per_page}`;
    const response = await http.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchOrganizationDetails = async (orgId: number) => {
  try {
    const url = `/master-data/organizations/${orgId}`;
    const response = await http.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const login = async (data: any) => {
  try {
    const response = await http.post("/master-data/authentication/login", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getUserAccountDetails = async () => {
  try {
    const response = await http.get("/master-data/account");
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


export const CreateOrganization = async (data: any) => {
  try {
    const response = await http.post("/master-data/organizations", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
export const CreateUser = async (data: any) => {
  try {
    const response = await http.post("/master-data/users", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchOrganizationHierarchy = async (orgId: number) => {
  try {
    const url = `/master-data/organizations/${orgId}/parent-hierarchy`;
    const response = await http.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


