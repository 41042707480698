import { useEffect, useState } from "react";
import { Field, Label } from "../../Catalyst/fieldset";
import { Input } from "../../Catalyst/input";
import { Button } from "../../Catalyst/button";
import { Dialog, DialogActions, DialogDescription, DialogTitle } from "../../Catalyst/dialog";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { createUserAsync } from "../../../store/thunks/OrganizationThunk";
import { STATUS, resetCreateUserStatus } from "../../../store/slices/OrganizationSlice";
import useRouteParams from "../../../hooks/useRouteParams";
import useRouteParameter from "../../../hooks/useRouteParamater";

export const CreateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const selectedOrgId = useSelector((state: RootState) => state.OrganizationListStore?.selectedParentOrgId) ?? undefined;// location.state?.selectedOrgDetails; // Access selectedOrgDetails from location state
  const [routePath, setRoutePath] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [contactEmail, setContactEmail] = useState<string>('');
  const [externalUniqueId, setExternalUniqueId] = useState<string>('');
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalDesc, setModalDesc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const organizationDetail = useSelector(
    (state: RootState) => state?.OrganizationListStore
  );
  const { lastParam, fullPath } = useRouteParameter();
  useRouteParams((params) => {
    setRoutePath(params);
  });
  useEffect(() => {
    if (organizationDetail?.createUserStatus === STATUS.SUCCESS) {
      setLoading(false);
      setModalTitle("User Created");
      setModalDesc(`User created sucessfully the user Id is ${organizationDetail?.createUserResponse?.id}`);
      setIsModalOpen(true);
    } else if (organizationDetail?.createUserStatus === STATUS.FAILED) {
      setLoading(false);
      setModalTitle("Error creating user");
      setModalDesc("User could not be created, please try again later.");
      setIsModalOpen(true);
    }
  }, [organizationDetail?.createUserStatus]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload = {
      "organization_id": lastParam||organizationDetail.selectedParentOrgId,
      "email": contactEmail,
      "first": firstName,
      "last": lastName,
      "external_unique_id": externalUniqueId,
      "user_role": "FINLEY_USER",
    };
    await dispatch(createUserAsync(payload));
    setContactEmail("");
    setFirstName("");
    setLastName("");
    setExternalUniqueId("")
  };

  const handleCancel = () => {
    navigate(`/org-home/${routePath}`)
  }
  return (
    <div className="w-full">
    <div className="w-[50%] ml-5">
      <h1 className="mb-6 mt-10 text-xl font-semibold">Create Organization</h1>
      
      <form onSubmit={handleSubmit} className="space-y-4">
          <Field>
            <Label>First name</Label>
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              name="First name"
              placeholder="Enter User First Name"
            />
          </Field>
          <Field>
            <Label>Last name</Label>
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              name="Last name"
              placeholder="Enter User Last Name"
            />
          </Field>

          <Field>
            <Label>Email</Label>
            <Input
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              name="Email"
              placeholder="Enter Email Id"
            />
          </Field>

          <Field>
            <Label>External Unique Id</Label>
            <Input
              value={externalUniqueId}
              onChange={(e) => setExternalUniqueId(e.target.value)}
              name="Email"
              placeholder="Enter External Unique Id"
            />
          </Field>

          <div className="flex space-x-4">
            <Button type="submit" className="cursor-pointer mt-4" disabled={loading}>
              {loading ? 'Creating...' : 'Create User'}
            </Button>
            <Button type="button" className="cursor-pointer mt-4" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>

        <Dialog open={isModalOpen} onClose={setIsModalOpen}>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogDescription>
            {modalDesc}
          </DialogDescription>

          <DialogActions>
            <Button plain onClick={() => { dispatch(resetCreateUserStatus()); setIsModalOpen(false); setLoading(false) }}>
              Create one more
            </Button>
            <Button className="cursor-pointer" onClick={async () => { await dispatch(resetCreateUserStatus()); navigate(`/org-home/${routePath}`) }}>Okay</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
