import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../Catalyst/dropdown';
import { Avatar } from '../Catalyst/avatar';
import { ArrowRightStartOnRectangleIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { SidebarItem } from '../Catalyst/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { resetLoginStatus } from '../../store/slices/OrganizationSlice';
import { useNavigate } from 'react-router';
import { getUserAccountDetailsAsync } from "../../store/thunks/OrganizationThunk";
import React, { useEffect } from 'react';

const FooterContent = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const userAccount = useSelector((state: RootState) => state.OrganizationListStore?.userAccount);

  // Only fetch data if it hasn't been fetched yet
  useEffect(() => {
    if (!userAccount) {
      const fetchData = async () => {
        await dispatch(getUserAccountDetailsAsync());
      };
      fetchData();
    }
  }, [dispatch, userAccount]);

  const handleSignOut = async () => {
    await dispatch(resetLoginStatus());
    localStorage.removeItem('accessToken');
    navigate('/');
  };

  return (

    <Dropdown>
      <DropdownButton as={SidebarItem}>
        <span className="flex min-w-0 items-center gap-3">
          <Avatar src="/profile-photo.jpg" className="size-10" square alt="" />
          <span className="min-w-0">
            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{userAccount?.first || ''}</span>
            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
              {userAccount?.email || ''}
            </span>
          </span>
        </span>
        <ChevronUpIcon />
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="top start">
        <DropdownItem onClick={handleSignOut}>
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Sign out</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>

  );
});

export default FooterContent;
