import { useEffect, useState } from "react";
import logo from "../../../assets/Logo.png";
import googleLogo from "../../../assets/googleIcon.svg";
import LockIcon from "../../../assets/LockIcon";
import EmailIcon from "../../../assets/EmailIcon";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { fetchOrganizationListAsync, loginUserAsync } from "../../../store/thunks/OrganizationThunk";
import { STATUS } from "../../../store/slices/OrganizationSlice";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);  // New loading state
    const dispatch = useDispatch<AppDispatch>();
    const loginDetails = useSelector(
        (state: RootState) => state.OrganizationListStore
    );

    const handleLogin = async () => {
        setLoading(true);  // Start loading
        await dispatch(loginUserAsync({ email, password }));
        setLoading(false);  // End loading
    };

            
    useEffect(() => {
        const fetchData = async () => {
            if (loginDetails.loginStatus === STATUS.SUCCESS) {
                const accessToken: string | undefined = loginDetails?.loginResponse?.access_token;
                localStorage.setItem('accessToken', accessToken as string);
                
                // Fetch the organization list
                await dispatch(fetchOrganizationListAsync({
                    page: 1,
                    per_page: 25,
                    orgId: 0,
                }));
    
                   if (loginDetails.ParentOrganizationList?.items!== undefined && loginDetails.ParentOrganizationList?.items?.length > 0) {
                    const orgId = loginDetails.ParentOrganizationList.items[0]?.id;
                    navigate(`/org-home/${orgId}`);
                } else {
                    navigate("/org-home"); // TODO: Where to navigate when no organization is found.
                }
            } else if (loginDetails.loginStatus === STATUS.FAILED) {
                setError("Login failed. Please check your credentials.");
                setEmail("");
                setPassword("");
            }
        };
    
        fetchData();
    }, [loginDetails.loginStatus, loginDetails.ParentOrganizationList, dispatch]);

    return (
        <div className="flex flex-col px-12 justify-center items-center  h-screen">
            <img src={logo} height="40px" width="100px" alt="Logo" />
            <div className="mt-24 relative">
                <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="w-[343px] border-[1.5px] px-3 h-[56px] pl-14 border-black rounded-[20px]"
                    placeholder="abc@example.com"
                />
                <EmailIcon />
            </div>
            <div className="mt-4">
                <div className="relative">
                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className=" w-[343px] border-[1.5px] pl-14 px-3 h-[54px] border-black rounded-2xl"
                        placeholder="*********"
                    />
                    <LockIcon />
                </div>
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <div className="text cursor-pointer text-blue mt-4 underline">
                Forgot Password?
            </div>
            <button
                onClick={handleLogin}
                className={`cursor-pointer bg-loginBg mt-4 text2 text-white px-8 py-2 rounded-3xl ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading} // Disable button when loading
            >
                {loading ? 'Logging in...' : 'Login'} {/* Show loader text */}
            </button>
            <div className="mt-16 border-t-2 pt-[15px] w-full flex justify-center ">
                <div className="flex ">
                    <div className="text3">Don’t have an account? </div>
                    <div className="text2 cursor-pointer text-blue ml-1 underline">Register</div>
                </div>
            </div>
            <div className="cursor-pointer text4 flex items-center mt-[15px] border-[1.5px] border-black rounded-2xl px-[30px] py-[10px]">
                <div className="mr-2"><img src={googleLogo} alt="googleLogo" /></div>
                <div>Continue with Google</div>
            </div>
        </div>
    );
}

export default Login;
