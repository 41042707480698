import { Route, Routes, useLocation } from 'react-router-dom';
import Login from '../pages/Home/Login';
import { CreateOrg } from '../pages/CreateOrganization/CreateOrg';
import { OrgView } from '../pages/OrgView/OrgView';
import { CreateUser } from '../pages/CreateUser/CreateUser';

import {
    Sidebar, SidebarHeader, SidebarFooter, SidebarBody, SidebarSection, SidebarItem, SidebarLabel
} from '../Catalyst/sidebar';
import { Navbar, NavbarSection, NavbarSpacer } from '../Catalyst/navbar';
import { SidebarLayout } from '../Catalyst/sidebar-layout';
import { Cog6ToothIcon, HomeIcon, TicketIcon } from '@heroicons/react/20/solid';

import HeaderContent from "./HeaderContent";
import FooterContent from "./FooterContent";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { OrgHome } from '../pages/Home/OrgHome';
import { Settings } from '../pages/Settings/Settings';
import useRouteParams from '../../hooks/useRouteParams';
import { useEffect, useState } from 'react';
import useRouteParameter from '../../hooks/useRouteParamater';
import { fetchOrganizationHierarchyAsync } from '../../store/thunks/OrganizationThunk';
import { BreadCrumbs } from './BreadCrumbs';


export function Menu() {
    const dispatch = useDispatch<AppDispatch>();
    const [routePath, setRoutePath] = useState<string>('');
    const selectedParentOrgId = useSelector((state: RootState) => state.OrganizationListStore?.selectedParentOrgId) ?? undefined;
    const parentHierarchy = useSelector((state: RootState) => state.OrganizationListStore?.parentHierarchyOrganizationList) ?? [];
    const { lastParam, fullPath } = useRouteParameter()
    const [pages, setPages] = useState<Array<{ name: string, href: string, current: boolean }>>([]);
    const location = useLocation();
    useRouteParams((params) => {
        if (!params) {
            setRoutePath(selectedParentOrgId?.toString() || '');

        }
        else {
            setRoutePath(params);
        }


    });

    useEffect(() => {
        // Function to fetch organization hierarchy
        const fetchOHierarchy = async (orgId: number) => {
            try {
                await dispatch(fetchOrganizationHierarchyAsync(orgId)); // Wait for the org hierarchy to be fetched
            } catch (error) {
                console.error('Error fetching organization hierarchy:', error);
            }
        };

        let pages: Array<{ name: string; href: string; current: boolean }> = [];

        if (lastParam || selectedParentOrgId) {
            const orgId = lastParam || selectedParentOrgId;
            if (orgId) {
                fetchOHierarchy(orgId); // Fetch the organization hierarchy asynchronously
            }
        } else {
            setPages([]); // Reset pages if no orgId is present
        }
    }, [lastParam, selectedParentOrgId]); // Trigger only when lastParam or selectedParentOrgId changes

    // Watch for updates in parentHierarchy and update pages
    useEffect(() => {
        if (parentHierarchy.length > 0) {

            setPages([]);
            const fullPath = location.pathname;

            // Split the path into segments
            const pathSegments = fullPath.split('/').filter(Boolean); // Filter to remove empty segments

            const orgId = lastParam || selectedParentOrgId;
            let pages: Array<{ name: string; href: string; current: boolean }> = [];

            parentHierarchy.forEach((element, index) => {
                // Determine the base path based on the current URL
                let basePath = '/org-view'; // Default to '/Org-View'

                if (pathSegments[0] && pathSegments[0].toLowerCase() === 'create-org') {
                    basePath = '/org-home'; // Replace with '/Org-Home' if the path starts with 'create-org'
                }

                // Construct the href with the base path
                const href = basePath + '/' + pathSegments.slice(1, index + 2).join('/');

                pages.push({
                    name: element.name,
                    href: href,
                    current: orgId === element.id
                });
            });

            setPages(pages); // Set the new pages state
        }
        else {
            setPages([]);
        }
    }, [location.pathname, parentHierarchy, lastParam, selectedParentOrgId]); // Update pages whenever parentHierarchy changes


    return (
        <div>
            {location.pathname !== '/' && (
                <div>

                    <SidebarLayout
                        navbar={
                            <Navbar>
                                <NavbarSpacer />
                                <NavbarSection>
                                </NavbarSection>
                            </Navbar>
                        }
                        sidebar={
                            <Sidebar>
                                <SidebarHeader>
                                    <HeaderContent></HeaderContent>
                                </SidebarHeader>
                                <SidebarBody>
                                    <SidebarSection>
                                        <SidebarItem href={`/org-home${fullPath}`}>
                                            <HomeIcon />
                                            <SidebarLabel>Home</SidebarLabel>
                                        </SidebarItem>

                                        <SidebarItem href={`/settings${fullPath}`}>
                                            <Cog6ToothIcon />
                                            <SidebarLabel>Settings</SidebarLabel>
                                        </SidebarItem>

                                        <SidebarItem href={`/org-view${fullPath}`}>
                                            <TicketIcon />
                                            <SidebarLabel>Organizations</SidebarLabel>
                                        </SidebarItem>

                                    </SidebarSection>
                                </SidebarBody>
                                <SidebarFooter className="max-lg:hidden">
                                    <FooterContent></FooterContent>
                                </SidebarFooter>
                            </Sidebar>
                        }
                    >
                        <div className="w-full"> 
                        <>
                            <div className='min-h-3'><BreadCrumbs pages={pages}></BreadCrumbs></div>
                            <Routes>
                                <Route path="/org-view" element={<OrgView />} />
                                <Route path="/org-view/*" element={<OrgView />} />

                                <Route path="/create-org" element={<CreateOrg />} />
                                <Route path="/create-org/*" element={<CreateOrg />} />

                                <Route path="/create-user" element={<CreateUser />} />
                                <Route path="/create-user/*" element={<CreateUser />} />

                                <Route path="/org-home" element={<OrgHome />} />
                                <Route path="/org-home/*" element={<OrgHome />} />
                                <Route path="/org-home/:id" element={<OrgHome />} />

                                <Route path="/settings" element={<Settings />} />
                                <Route path="/settings/*" element={<Settings />} />

                            </Routes>
                        </>
                        </div> 
                    </SidebarLayout>
                </div>
            )}

            <div className="w-full">
                <Routes>
                    <Route path="/" element={<Login />} />
                </Routes>
            </div>
        </div>
    )
}
export default Menu;